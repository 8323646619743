/*
$(document).ready(function (){
	// this jquery code
})*/

import Inputmask from "inputmask";
var selector = document.getElementById("uk-phone");

console.log(selector)

/*var im = new Inputmask("+7 (999) 999-99-99");
im.mask(selector);*/

/*import Inputmask from "inputmask";
Inputmask("+7 (999) 999-99-99").mask(".uk-phone")*/

