<template>
	<div>
		<a class="uk-display-block" :class="area" :href="'#modal-callback'+area" uk-toggle>Заказать звонок</a>
		<div :id="'modal-callback'+area" class="uk-flex-top" uk-modal>
			<div class="uk-modal-dialog uk-margin-auto-vertical">
				<button class="uk-modal-close-outside" type="button" uk-close></button>
				<div class="uk-modal-header">
					<h2 class="uk-modal-title">Заказать звонок</h2>
				</div>
				<div class="uk-modal-body">
					<fieldset class="uk-fieldset">
						<div class="uk-margin">
							<input class="uk-input" type="text" :class="errorname" onkeydown="this.errorname = ''" v-model="name" placeholder="Имя">
						</div>
						<div class="uk-margin">
							<input class="uk-input uk-phone" ref="field" type="text" v-model="phone" placeholder="Номер телефона">
						</div>
					</fieldset>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<button class="uk-button uk-button-default uk-modal-close" type="button">Отмена</button>
					<button class="uk-button uk-button-primary" @click="sendForm" type="button">Отправить</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Inputmask from 'inputmask';
import UIkit from 'uikit';
export default {
	name: "CallbackComponent",
	props: ['area'],
	mounted() {
		let im = new Inputmask("+7 (999) 999-99-99")
		im.mask(this.$refs.field);
	},
	data: () => ({
		name: '',
		phone: '',
		errorname: ''
	}),
	methods: {
		sendForm() {
			if (this.name.length < 3) {
				this.errorname = 'uk-form-danger'
			}
			axios.get("/index.php?route=api/callback/add&name="+this.name+"&phone="+this.phone)
				.then(res => {
					console.log(res.data)
					if(res.data == 'ok'){
						UIkit.modal("#modal-callback"+this.area).hide();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
