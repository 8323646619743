var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "uk-display-block",
        class: _vm.area,
        attrs: { href: "#modal-callback" + _vm.area, "uk-toggle": "" },
      },
      [_vm._v("Заказать звонок")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-flex-top",
        attrs: { id: "modal-callback" + _vm.area, "uk-modal": "" },
      },
      [
        _c("div", { staticClass: "uk-modal-dialog uk-margin-auto-vertical" }, [
          _c("button", {
            staticClass: "uk-modal-close-outside",
            attrs: { type: "button", "uk-close": "" },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-body" }, [
            _c("fieldset", { staticClass: "uk-fieldset" }, [
              _c("div", { staticClass: "uk-margin" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  staticClass: "uk-input",
                  class: _vm.errorname,
                  attrs: {
                    type: "text",
                    onkeydown: "this.errorname = ''",
                    placeholder: "Имя",
                  },
                  domProps: { value: _vm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "uk-margin" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phone,
                      expression: "phone",
                    },
                  ],
                  ref: "field",
                  staticClass: "uk-input uk-phone",
                  attrs: { type: "text", placeholder: "Номер телефона" },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.phone = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" },
              },
              [_vm._v("Отмена")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { type: "button" },
                on: { click: _vm.sendForm },
              },
              [_vm._v("Отправить")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-modal-header" }, [
      _c("h2", { staticClass: "uk-modal-title" }, [_vm._v("Заказать звонок")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }